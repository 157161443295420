import { React, useEffect, useState , useCallback} from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchQuestions } from "../../redux/slices/FetchQuestionsSlice";
import { fetchUser } from "../../redux/slices/userSlice";
import ProgressBar from "../../components/ProgressBar";
import playIcon from "../../assets/play.svg";
import like from "../../assets/like.png";
import like_done from "../../assets/like_done.png";
import dislike from "../../assets/dislike.png";
import dislike_done from "../../assets/dislike_done.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import conceptsPlaylistData from "../../data/ConceptsPlaylist";
import Video from "../../assets/video.png";
import Select from "react-select";
import CircularProgress from '@mui/material/CircularProgress';
import { FaLightbulb } from "react-icons/fa";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, TextareaAutosize } from '@mui/material';
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineEdit } from "react-icons/md";
import ReactQuill from 'react-quill';
import {Link} from "@mui/material";
import { FaLinkedin } from "react-icons/fa6";


const WHATSAPP_GROUP_LINK = "https://chat.whatsapp.com/HUwWZaokuyOGGNRSgGMOpz";
const like_with_Reason = ["Good Quality", "Intuition build", "Good Explanation"];
const disLike_with_Reason = ["Poor Explanation", "Poor Quality", "Poor understanding"];

const Solve = () => {
  const [visitor_count, set_visitor_count] = useState(null);
  const [reason, setReason] = useState([]);
  const [knowReason , setKnowReason] = useState(true); // true is for the like and false is for the dislike 
  const [showPopUp,setShowPopUp] = useState(0);// 1 for the feedback , 0 for the video , 2 for the hint section 
  const [togglePopup,setTogglePopup] = useState(-1);
  const [loading,setLoading] = useState(false);

  const handleReasonSelection = (selectedReason) => {
    const newSelectedReason = selectedReason.replace(" ","_");
    console.log(newSelectedReason);
    if(reason.includes(newSelectedReason))
    {
      setReason(reason.filter(res=>res!==newSelectedReason));
    }
    else
    {
        setReason([...reason,newSelectedReason]);
    }
};


  const update_count = async () => {
    if (!visitor_count) {
      await axios.post("/updateCount", {}).then((res) => {
        set_visitor_count(res.data.count);
      });
    }
  }
  update_count();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const questions = useSelector((state) => state.CurrentQuestions.data);

  

  // spreadsheet






  const solvedQuestions = useSelector(
    (state) => state.user.user.solvedQuestions
  );
  const bookmarkedQuestions = useSelector(
    (state) => state.user.user.bookmarkedQuestions
  );
  const reactions = useSelector((state) => state.user.user.reaction);
  const userID = useSelector((state) => state.user.user._id);
  const userData = useSelector((state) => state.user.user);

  let data = window.localStorage.getItem("SELECTED_TAG_DATA"); //Fetching Tag from localstorage
  let tagData = JSON.parse(data);
  let currentTagName = tagData.name;
  let currentTagPercent = tagData.percent;
  let currentConceptPlaylistLink = "";
  
  try {
    currentConceptPlaylistLink = conceptsPlaylistData[currentTagName];
  } catch (error) {
    console.log(error);
  }

     

  useEffect(() => {
    dispatch(fetchQuestions(currentTagName));
    HandleDifficulty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTagName]);

  // <-------- Mark the question in DB ---------->

  const [hintPopOver , setHintPopOver] = useState(false);// After Marking the question a popover will be displayed at hint aas well as the like button 
  const MarkTheQuestion = async (isSolved) => {
    if (!userID) {
      navigate("/login");
    } else {
      toast.loading("Updating...");
      console.log(curEle);
      await axios
        .patch("/questions", {
          quesID: curEle._id,
          difficulty: curEle.difficulty,
          userID: userID,
          solve: isSolved,
          UpdateSolve: true,
          tags: curEleTag,
          date_solved: new Date().toISOString().split('T')[0],
        })
        .then((res) => {
          toast.dismiss();
          if (res.data) {
            if (isSolved) {
              toast.info("Question unmarked!")
              let solvedQues = tagData.solved - 1;
              let totalQues = tagData.total;
              window.localStorage.setItem("SELECTED_TAG_DATA", JSON.stringify({ name: currentTagName, percent: Math.floor((solvedQues / totalQues) * 100), total: totalQues, solved: solvedQues }));
              currentTagPercent = Math.floor((solvedQues / totalQues) * 100);
            } else {
              toast.success("Question marked Done");
              let solvedQues = tagData.solved + 1;
              let totalQues = tagData.total;
              window.localStorage.setItem("SELECTED_TAG_DATA", JSON.stringify({ name: currentTagName, percent: Math.floor((solvedQues / totalQues) * 100), total: totalQues, solved: solvedQues }));
              currentTagPercent = Math.floor((solvedQues / totalQues) * 100);
            }
            dispatch(fetchUser(res.data)); //Updating the User Data
          } else {
            toast.error("something went wrong!");
          }
        }).then(()=>{
        if(!isSolved && !showPopUp)setHintPopOver(true); // when the question is solved or any hint popup is being open then no need to show popover else we can show
        }).finally(()=>{
          setTimeout(()=>{
            setHintPopOver(false);
           },5000)
        })
    }
  };

  const isSolved = (ele) => {
    if (solvedQuestions) {
      return solvedQuestions.filter((id) => id.question_id === ele._id).length !== 0
    }
  };
  // <-------- BookMark the question in DB ---------->

  const isBookMarked = (ele) => {
    if (bookmarkedQuestions) {
      if (
        bookmarkedQuestions.filter((id) => id.includes(ele._id)).length !== 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const BookMarkQuestion = async (isBookmarked) => {
    if (!userID) {
      navigate("/login");
    } else {
      await axios
        .patch("/questions", {
          quesID: curEle._id,
          userID: userID,
          bookmark: isBookmarked,
        })
        .then((res) => {
          if (res.data) {
            isBookmarked
              ? toast.info("Question removed from bookmark!")
              : toast.info("Question bookmarked!");
            dispatch(fetchUser(res.data)); //Updating the User Data
          } else {
            toast.error("something went wrong!");
            console.log("Updated user not received");
          }
        });
    }
  };

  // <-------- Like and Dislike the question in DB ---------->

  const reaction_value = (ele) => {
    // fetch value from database
    if (reactions) {
      if (reactions.filter((id) => id.question_id === ele._id).length !== 0) {
        const value = reactions.find((id) => id.question_id === ele._id).liked;
        return value + 1;
        // value === 1 -> no reaction
        // value === 2 -> liked
        // value === 3 -> disliked
      } else {
        return 0; // question not present in the array
      }
    }
  };
  const likeQuestion = async () => {
    if (!userID) {
      navigate("/login");
    } else {
      let ques_like = 0;
      let ques_dislike = 0;
      let status;
      const value = like_value;
      if (user_like) {
        set_user_like(0);
        set_question_likes(question_likes - 1);
        set_like_value(1);
        ques_like = -1;
        status = 0; // -> set reaction to none
      } else {
        if (user_dislike) {
          set_user_like(1);
          set_user_dislike(0);
          set_question_likes(question_likes + 1);
          set_question_dislikes(question_dislikes - 1);
          set_like_value(2);
          ques_like = 1;
          ques_dislike = -1;
        } else {
          set_user_like(1);
          set_question_likes(question_likes + 1);
          set_like_value(2);
          ques_like = 1;
        }
        status = 1; // set reaction to liked
      }
      setLoading(true);
      await axios
        .patch("/questions", {
          quesID: curEle._id,
          userID: userID,
          like: true,
          value: value,
          status: status,
          likes: ques_like,
          dislikes: ques_dislike,
          reason:reason
        })
        .then((res) => {
          setLoading(true);
          if (res.data) {
            dispatch(fetchUser(res.data)); //Updating the User Data
            dispatch(fetchQuestions(currentTagName));
          } else {
            toast.error("something went wrong!");
            console.log("Updated user not received");
          }
        }).finally(()=>setLoading(false));
    }
  };

  const dislikeQuestion = async () => {
    if (!userID) {
      navigate("/login");
    } else {
      let ques_like = 0;
      let ques_dislike = 0;
      let status;
      const value = like_value;
      if (user_dislike) {
        set_user_dislike(0);
        set_question_dislikes(question_dislikes - 1);
        set_like_value(1);
        ques_dislike = -1;
        status = 0; // set reaction to none
      } else {
        if (user_like) {
          set_user_like(0);
          set_user_dislike(1);
          set_question_likes(question_likes - 1);
          set_question_dislikes(question_dislikes + 1);
          set_like_value(3);
          ques_like = -1;
          ques_dislike = 1;
        } else {
          set_user_dislike(1);
          set_question_dislikes(question_dislikes + 1);
          set_like_value(3);
          ques_dislike = 1;
        }
        status = 1; // set reaction to disliked
      }
      setLoading(true);
      await axios
        .patch("/questions", {
          quesID: curEle._id,
          userID: userID,
          like: false,
          value: value,
          status: status,
          likes: ques_like,
          dislikes: ques_dislike,
          reason:reason
        })
        .then((res) => {
          if (res.data) {
            dispatch(fetchUser(res.data)); //Updating the User Data
            dispatch(fetchQuestions(currentTagName));
          } else {
            toast.error("something went wrong!");
            console.log("Updated user not received");
          }
        }).finally(()=>setLoading(false));
    }
  };
  // <-------- activeQuestion CSS Handler ---------->

  const activeTitleClassCSS =
    "bg-blue5 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-blue5  dark:bg-blue dark:group-hover:bg-blue";
  const nonActiveTitleClassCSS =
    "bg-blue7 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-blue5  dark:bg-dark3 dark:group-hover:bg-blue";
  const ActiveSolvedTitleClassCSS =
    "bg-green-400 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-green-400  dark:bg-green-400 dark:group-hover:bg-green-400";
  const nonActiveSolvedTitleClassCSS =
    "bg-green-500 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-green-400  dark:bg-green-500 dark:group-hover:bg-green-400";
  //
  const nonActiveBookmarkTitleClassCSS =
    "bg-amber-500 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-amber-400  dark:bg-amber-500 dark:group-hover:bg-amber-400";
  const activeBookmarkTitleClassCSS =
    "bg-amber-500 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-amber-400  dark:bg-amber-500 dark:group-hover:bg-amber-400";
  const ActiveBookmarkSolvedTitleClassCSS =
    "bg-amber-400 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-amber-400  dark:bg-amber-400 dark:group-hover:bg-amber-400";
  const nonActiveBookmarkSolvedTitleClassCSS =
    "bg-amber-400 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-amber-500  dark:bg-amber-500 dark:group-hover:bg-amber-500";

  const activeIconClassCSS =
    "bg-blue5 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px]  dark:bg-blue ease-in-out duration-200 group-hover:bg-blue dark:group-hover:bg-blue";
  const nonActiveIconClassCSS =
    "bg-blue7 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-dark3 ease-in-out duration-200 group-hover:bg-dark3 dark:group-hover:bg-blue";
  const ActiveSolvedIconClassCSS =
    "bg-green-400 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-green-400 ease-in-out duration-200 group-hover:bg-green-400 dark:group-hover:bg-green-400";
  const nonActiveSolvedIconClassCSS =
    "bg-green-500 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-green-500 ease-in-out duration-200 group-hover:bg-green-400 dark:group-hover:bg-green-400";
  //
  const nonActiveBookmarkIconClassCSS =
    "bg-amber-500 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-amber-400 ease-in-out duration-200 group-hover:bg-amber-400 dark:group-hover:bg-amber-400";
  const activeBookmarkIconClassCSS =
    "bg-amber-500 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-amber-400 ease-in-out duration-200 group-hover:bg-amber-400 dark:group-hover:bg-amber-400";
  const ActiveBookmarkSolvedIconClassCSS =
    "bg-amber-400 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-amber-400 ease-in-out duration-200 group-hover:bg-amber-400 dark:group-hover:bg-amber-400";
  const nonActiveBookmarkSolvedIconClassCSS =
    "bg-amber-400 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-amber-500 ease-in-out duration-200 group-hover:bg-amber-500 dark:group-hover:bg-amber-500";


  const solvedButtonCSS = {
    text: "text-white dark:text-white text-sm lg:text-lg font-medium group-hover:text-white dark:group-hover:text-white",
    icon: "fa-solid dark:text-white fa-check text-white group-hover:text-white dark:group-hover:text-white",
    div: "group cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-2 h-10 w-[100px] lg:w-[120px] hover:bg-blue dark:bg-blue dark:hover:bg-blue  ease-in-out duration-200 ",
    bookmarkeDiv:
      "group cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[140px] lg:w-[180px] hover:bg-blue dark:bg-blue dark:hover:bg-blue  ease-in-out duration-200",
    bookmarkedIcon:
      "fa-solid fa-bookmark text-white group-hover:text-white dark:group-hover:text-white",
  };

  const [curIdx, setCurIdx] = useState();
  const [curEle, setCurEle] = useState();
  const [curEleTag, setCurEleTag] = useState();

  const [question_likes, set_question_likes] = useState();
  const [question_dislikes, set_question_dislikes] = useState();
  const [user_like, set_user_like] = useState();
  const [user_dislike, set_user_dislike] = useState();
  const [like_value, set_like_value] = useState();
  


  // spreadsheet

  // const handleSaveToSheet = async () => {
  //   const data = {
  //     title: curEle?.title,
  //     difficulty: curEle?.difficulty,
  //     tags: curEle?.tags,
  //     link: curEle?.link,
  //     video: curEle?.video,
  //     id: curEle?._id,
  //     people: "admin",
  //   };
  
  //   try {
  //     const API_KEY = "AIzaSyC1KeI4yJiQTtTXKuscrGbVuNMLAVjz3nI"; // Replace with your actual API key
  //     const SPREADSHEET_ID = "1sEGlw5URdB6vAnuLGccKeUfwrQfrb9mYrcEBxbRg6V8"; // Replace with your spreadsheet ID
  //     const SHEET_NAME = "Sheet1"; // Replace with your sheet name
  
  //     // API URL
  //     const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_NAME}!A1:append?valueInputOption=USER_ENTERED&key=${API_KEY}`;
  
  //     // Request body
  //     const body = {
  //       values: [
  //         [
  //           data.title,
  //           data.difficulty,
  //           data.tags.join(", "), // Convert tags array to a string
  //           data.link,
  //           data.video,
  //           data.id,
  //           data.people,
  //         ],
  //       ],
  //     };
  
  //     // Send POST request
  //     const response = await axios.post(url, body);
  
  //     // Handle success
  //     if (response.status === 200 || response.status === 204) {
  //       alert("Data saved successfully to Google Sheets!");
  //       console.log("Response:", response.data);
  //     } else {
  //       alert("Failed to save data. Please try again.");
  //       console.error("Response:", response.data);
  //     }
  //   } catch (error) {
  //     // Handle error
  //     console.error("Error saving data to Google Sheets:", error.response || error.message);
  //     alert("An error occurred while saving data. Please check the console for details.");
  //   }
  // };

  const [diff , setDiff] = useState("")

  const handleSave = async () => {

    const data = {
      title: curEle?.title,
      difficulty: curEle?.difficulty,
      tags: curEle?.tags,
      link: curEle?.link,
      video: curEle?.video,
      id: curEle?._id,
      user: "admin",
      desired: diff,
    };

    try {
      const response = await axios.post(
        "/savetosheet",
        data
      );
    
      console.log("Success:", response.data);
      alert("Data saved to the spreadsheet successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Error saving data to the spreadsheet!");
    }
  };



  const activeQuestion = (ele, idx, e) => {
    setCurIdx(idx);
    setCurEle(ele);

    setShowPopUp(0);//popup of like and dilike to be closed when I move to different question
    setHintPopOver(false);//The popover of the hint section should be false when Moved to different question 
    setHintAnswer("");// when click on add hint then a new a black feild should appear

    setCurEleTag(ele.tags);

    set_question_likes(ele.likes);
    set_question_dislikes(ele.dislikes);

    const value = reaction_value(ele);

    set_like_value(value);

    if (value !== 2 && value !== 3) {
      // no reaction
      set_user_like(0);
      set_user_dislike(0);
    } else if (value === 2) {
      // liked
      set_user_like(1);
      set_user_dislike(0);
    } else {
      // disliked
      set_user_like(0);
      set_user_dislike(1);
    }
  };

  // <---------------------------------------------->

  const progressBarCSS = {
    bgLine: "bg-white px-1 h-4 dark:bg-darkBlue",
    frontLine: "bg-blue7 h-2.5 dark:bg-blue2",
    text: "text-white text-sm lg:text-md font-medium dark:text-blue2",
    width: 80,
  };
  function f() {
    // console.log(curEle);
    axios
      .post("/pre-potd", {
        curPotd: curEle,
      })
      .then((response) => {
        console.log("success");
      })
      .catch((error) => {
        console.log("error");
      });
  }
  const options = [
    { value: "All", label: "All" },
    { value: "Easy", label: "Easy" },
    { value: "Medium", label: "Medium" },
    { value: "Hard", label: "Hard" },
    { value: "Ninja", label: "Very Hard" },
  ];
  const [selectedOption, setSelectedOption] = useState("All");
  const [sortedQuestions, setSortedQuestions] = useState(questions);
  useEffect(() => {
    if (selectedOption === "All") {
      setSortedQuestions(questions);
    }
  }, [questions]);

  const HandleDifficulty = () => {
    if (selectedOption.value === "All") {
      setSortedQuestions(questions);

      return;
    }

    const sortedQues = questions.filter((question) => {
      return (
        question?.difficulty === selectedOption?.value ||
        selectedOption?.value === "All"
      );
    });

    setSortedQuestions(sortedQues);
  };

  useEffect(() => {
    HandleDifficulty();
  }, [selectedOption]);

  const customStyle = {
    placeholder: (base) => ({
      ...base,
      fontSize: "1em",
      color: "black",
      fontWeight: 600,
    }),
    value: (base) => ({
      ...base,
      fontSize: "5.2em",
      color: "black",
      fontWeight: 500,
    }),

    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      "margin-top": "1rem",
      padding: "0.3rem",
      "border-radius": "1.3rem",
      "padding-left": "1rem",
      "padding-right": "1rem",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        backgroundColor: isDisabled
          ? "#38B7FF"
          : isFocused
            ? "#006eff"
            : "#38B7FF",
        color: "white",
        "font-weight": "500",
        "font-size": "1.2rem",
        "margin-top": "-4px",
        "margin-bottom": "-4px",
      };
    },
  };
  const [maxmenuHeight, setMaxMenuHeight] = useState(200); // Default height for larger screens

  const LikeOrDislikePresent = (quesID)=>{
    const isPresent = userData.reaction.findIndex((currQuestion)=>quesID===currQuestion.question_id);
    
    if(isPresent===-1) return isPresent;
    else 
    {     
      return userData.reaction[isPresent].liked; 
    }
  }
  /*const  LikeOrDislikePresent = async (quesID) => {
    try {
      console.log(knowReason);
      const res = await axios.post("/isLikeorDislike", {
        quesID,
        knowReason,
        userID: userData._id
      });
      const val = res.data;
      console.log(res.data);
      if(val=="0")
      {
        knowReason?likeQuestion():dislikeQuestion();
        return 0;
      }
      else
      {
        return 1;
      } 

    } catch (err) {
      console.error(err);
    }
  };*/

  //console.log(togglePopup);


  useEffect(() => {
    const handlePopup = () => {
      console.log(knowReason);
      if (togglePopup === (knowReason ? 1 : 2)) {
        knowReason ? likeQuestion() : dislikeQuestion();
      }
    };

    handlePopup();
  }, [knowReason,togglePopup]);

  useEffect(() => {
    const handleResize = () => {
      // Adjust maxMenuHeight based on window size
      if (window.innerWidth < 768) {
        setMaxMenuHeight(100); // For smaller screens
      } else {
        setMaxMenuHeight(200); // Default height for larger screens
      }
    };

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  

  useEffect(() => {
		const getViews = async () => {
		  try {
			const data = { view: 1, quesID: curEle._id, name: userID };
			const res = await axios.patch("/questions", data)
      .then((res)=>dispatch(fetchQuestions(currentTagName)));
			console.log(res.data.msg);
		  } catch (err) {
			console.error("Error is :", err);
		  }
		}
		getViews();
	  }, [userID,curEle?._id]); 
	  console.log(curEle?._id);

	  const convert_Small = (value)=>{// conversion of views into K terms 
		if(value<=999)return value;
		else return  Number.parseFloat(value/1000).toFixed(2)+ "k"
	  }
    
    const [tabValue, setTabValue] = useState(1);//For the Hint section Tabs

   const handleTabsChange = (event, newValue) => {//Hint Section the Tab to navigate 
    setTabValue(newValue);
    setKnowReason(1);// when On moving the new Tab edit option should not be visible 
   };
   const [hintAnswer,setHintAnswer] = useState(""); // CRUD operation to perform in hint section 
   const AddHints = (optionValue , hintIndex)=>{
    try { 
      if(!hintAnswer.length && optionValue!==1){
        alert("Provide the Hint properly");
      }
      else if(!userData.isAdmin) // if he is not admin the the asnwers will be stored in the sheets
      {
        const res = axios.patch("/questions",{hint:1,quesID:curEle._id,options:0,hint_ans:hintAnswer})
        console.log(res);
        console.log(hintAnswer);
        setHintAnswer("");
        setShowPopUp(0);
        setKnowReason(1);
      }
      else{
        console.log(hintAnswer);
       const res = axios.patch("/questions",{hint:1,quesID:curEle._id,options:optionValue,hint_ans:hintAnswer,hint_index:hintIndex})
       console.log(res);
       setHintAnswer("");
       setShowPopUp(0);
       setKnowReason(1);
      }
    } catch (err) {
      console.log(err);
    }
   }
   
  useEffect(()=>{
    dispatch(fetchQuestions(currentTagName));
  },[knowReason,hintAnswer,showPopUp]);

  

  return (
    <>
      {sortedQuestions.length !== 0 ? (
        <>
          {/* <-------------- SideBar --------------> */}
          <section className="bg-gray-100 w-screen h-[60%] fixed lg:h-screen z-5 lg:w-[35%]  p-5 dark:bg-black">
            <div className=" bg-white p-3 rounded-lg flex flex-col items-center justify-center mt-[70px] dark:bg-gray-900">
              <h1 className="text-black dark:text-white text-lg lg:text-2xl font-semibold mb-3">
                {currentTagName}
              </h1>
              <div className="w-[80%]">
                <ProgressBar
                  width={currentTagPercent}
                  progressBarCSS={progressBarCSS}
                />
              </div>
            </div>
            <div className="text-[1.2rem] md:hidden font-semibold rounded-xl z-[200]">
              <Select
                options={options}
                placeholder={"Select Difficulty"}
                styles={customStyle}
                onChange={(value) => {
                  setSelectedOption(value);
                  HandleDifficulty();
                }}
                value={selectedOption}
                maxMenuHeight={150}
              ></Select>
            </div>
            <div className="text-[1.2rem] md:block hidden font-semibold rounded-xl z-[200]">
              <Select
                options={options}
                placeholder={"Select Difficulty"}
                styles={customStyle}
                onChange={(value) => {
                  setSelectedOption(value);
                  HandleDifficulty();
                }}
                value={selectedOption}
                maxMenuHeight={maxmenuHeight}
              ></Select>
            </div>
            <div className="mt-5 flex flex-col gap-3 h-[55%] lg:h-[72%] overflow-y-auto">
              {sortedQuestions &&
                sortedQuestions.map((ele, idx) => {
           
                  return (
                    <div
                      key={idx}
                      className="flex gap-4 justify-between items-center group"
                    >
                     
                      <div
                        onClick={(e) => {
                          activeQuestion(ele, idx);
                        }}
                        className={
                          idx === curIdx
                            ? ele && isSolved(ele)
                              ? isBookMarked(ele)
                                ? ActiveBookmarkSolvedTitleClassCSS
                                : ActiveSolvedTitleClassCSS
                              : isBookMarked(ele)
                                ? activeBookmarkTitleClassCSS
                                : activeTitleClassCSS
                            : ele && isSolved(ele)
                              ? isBookMarked(ele)
                                ? nonActiveBookmarkSolvedTitleClassCSS
                                : nonActiveSolvedTitleClassCSS
                              : isBookMarked(ele)
                                ? nonActiveBookmarkTitleClassCSS
                                : nonActiveTitleClassCSS
                        }
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p className="pointer-events-none text-white lg:text-md text-sm font-medium">
                          {ele.title} 
                        </p>
                        <div class="flex justify-between items-center">
                            <i class="fas fa-eye mr-1 text-white"></i>
                            <p class="mr-2 text-white">{convert_Small(ele.views.length)}</p>
                            {ele.video && (
                              <img
                                src={Video}
                                class="h-9"
                                alt="Video Solution"
                              />
                            )}
                        </div>

                      </div>
                      <div
                        className={
                          idx === curIdx
                            ? ele && isSolved(ele)
                              ? isBookMarked(ele)
                                ? ActiveBookmarkSolvedIconClassCSS
                                : ActiveSolvedIconClassCSS
                              : isBookMarked(ele)
                                ? activeBookmarkIconClassCSS
                                : activeIconClassCSS
                            : ele && isSolved(ele)
                              ? isBookMarked(ele)
                                ? nonActiveBookmarkSolvedIconClassCSS
                                : nonActiveSolvedIconClassCSS
                              : isBookMarked(ele)
                                ? nonActiveBookmarkIconClassCSS
                                : nonActiveIconClassCSS
                        }
                      >
                        {ele && isSolved(ele) ? (
                          <i className=" text-white fa-solid fa-check"></i>
                        ) : isBookMarked(ele) ? (
                          <i className=" fa-solid fa-bookmark text-white"></i>
                        ) : idx === curIdx ? (
                          <i className=" text-white fa-solid fa-angles-right"></i>
                        ) : (
                          <i className=" text-white fa-solid fa-code"></i>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </section>
          {/* <-------------- Main Section --------------> */}
          {curEle ? (
            <section className="bg-gray-100 lg:h-screen fixed top-[60%] w-screen h-[40%] lg:top-0 lg:right-[0%] lg:w-[65%] z-5 lg:z-10 flex flex-col items-center justify-between dark:bg-black">
              <div className="flex items-center justify-between mt-6 lg:mt-[90px] w-[100%]">
                <div className="w-[100%] px-[10%] flex flex-col items-center justify-center gap-5 lg:gap-14 mt-[10px]">
                  {/* ------------- like and dislike button ----------------- */}

                  <div className=" flex flex- items-center w-[80%] mt-[20px] lg:mb-[-30px]">
                  <div className="absolute left-14 mx-20 top-25">
                    <IoIosAddCircleOutline className={`${showPopUp !== 3?"text-white bg-blue p-2 rounded-full text-4xl transition-all duration-300 font-bold":
                        "bg-yellow-400 text-white p-2 rounded-full text-4xl  transition-all duration-300"}`}
                    onClick={() =>{
                      setKnowReason(1);// I used set know reason for the like and dislike as well for the edit option I have used it
                      // when I click on edit button and then I cliked on the add button then set know reason should be one   
                      setHintAnswer("") ; 
                      setShowPopUp(showPopUp===3?0:3)
                    }}
                  />
                    {
                      hintPopOver &&
                      <div className="absolute bg-white p-4 rounded-md shadow-md top-12 -left-20 max-w-xs z-50">
                      <div className="relative h-6">
                        <p className="font-small overflow-hidden whitespace-nowrap text-ellipsis">
                          Please Provide Hint
                        </p>
                        <div className="bg-white absolute rotate-45 w-5 h-5 -top-6 translate-x-20"></div>
                      </div>
                    </div>
                    }
                  </div>
                  {curEle.hints.length>0 ? <FaLightbulb // for the hint section to display the values
                      className={`${
                        showPopUp !== 2
                          ? "text-yellow-400 bg-blue p-2 rounded-full text-4xl mx-5 transition-all duration-300"
                          : "bg-yellow-400 text-white p-2 rounded-full text-4xl mx-5 transition-all duration-300"
                        }`}
                      onClick={() => setShowPopUp(showPopUp===2?0:2)}
                    />:null
                  }
                    <h1 className="mx-auto text-blue text-xl lg:text-2xl font-semibold ">
                      {curEle.title}
                    </h1>
                    <div className="flex flex-row cursor-pointer bg-blue-800">
                      {/* Left Half - Like Button */}
                      <div
                        className="flex flex-col items-center justify-center mt-1 border-4 border-r-0 border-black bg-blue5 rounded-l-lg hover:bg-blue7 px-2 py-1 ease-in-out duration-200"
                        onClick={() =>{
                          console.log(LikeOrDislikePresent(curEle._id))
                          setKnowReason(true);
                         // LikeOrDislikePresent(curEle._id)
                          //setShowPopUp(LikeOrDislikePresent(curEle._id)?true:false);
                          setTogglePopup(LikeOrDislikePresent(curEle._id));
                          setShowPopUp(1);
                          setReason([]) //I emptied it because for like I want different action and for dislike I want different actions 
                        }}
                      >
                        {!loading ?(user_like ? (
                          <img
                            src={like_done}
                            alt="Like"
                            width={30}
                            className="mb-1"
                          />
                        ) : (
                          <img
                            src={like}
                            alt="Like"
                            width={30}
                            className="mb-1"
                          />
                        ) ):(
                          <CircularProgress />
                        )
                        }
                        <span className="text-sm font-bold">
                          {!loading?question_likes:""}
                        </span>
                      </div>

                      {/* Right Half - Dislike Button */}
                      <div
                        className="flex flex-col items-center justify-center mt-1 border-4 border-black bg-blue5 rounded-r-lg hover:bg-blue7 px-2 py-1 ease-in-out duration-200"
                        onClick={() => {
                          console.log(LikeOrDislikePresent(curEle._id))
                          setKnowReason(false);
                          setTogglePopup(LikeOrDislikePresent(curEle._id));
                          //setShowPopUp(LikeOrDislikePresent(curEle._id)?true:false);
                          setShowPopUp(1);
                          setReason([])//I emptied it because for like I want different action and for dislike I want different actions 
                        }}
                      >
                        {!loading?(user_dislike ? (
                          <img
                            src={dislike_done}
                            alt="DisLike"
                            width={30}
                            className="mb-1"
                          />
                        ) : (
                          <img
                            src={dislike}
                            alt="DisLike"
                            width={30}
                            className="mb-1"
                          />
                        )):(
                          <CircularProgress />
                        )}
                        <span className="text-sm font-bold">
                          {!loading?question_dislikes:""}
                        </span>
                      </div>
                      {/*extreme right half for the linked in icon*/}
                      <div className="absolute flex items-center justify-center p-4 bg-white border-2 border-blue rounded-full mt-4 right-20">
                        <Link
                          href="https://www.linkedin.com/company/asksenior/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                        <FaLinkedin className="transform scale-150 "/>
                        </Link>
                      </div>
                    </div>
                  </div>
                  
                  {/*This popup appear when click on like or dislike */}
                  {/*console.log("Answer is => ",LikeOrDislikePresent(curEle._id))*/}
                  
                  {showPopUp===1 && togglePopup !== (knowReason ? 1 : 2) ? 
                    (
                     (    
                <div className="bg-white rounded-lg p-6 shadow-lg">
                <h2 className="text-xl font-semibold mb-4 text-blue-700">
                  {knowReason ? "What's the Reason you Liked the Question" : "What's the Reason you Disliked the Question"}
                </h2>
                <div className="flex flex-wrap justify-center">
                  {knowReason  //this is a conditional statement if click like or dislike then popup will be displayed else video will be displayed 
                    ? like_with_Reason.map((e) => (
                        <button
                          key={e}
                          onClick={() => handleReasonSelection(e)}
                          className={`px-4 py-2 rounded-md mx-2 mb-2 transition-transform duration-[10000ms] hover:scale-105 focus:outline-none ${
                            reason.includes(e.replace(" ","_"))
                              ? 'bg-transparent border-2 border-green-500 text-green-500'
                              : 'bg-green-500 text-white hover:bg-green-600'
                          }`}
                        >
                          {e}
                        </button>
                      ))
                    : disLike_with_Reason.map((e) => (
                        <button
                          key={e}
                          onClick={() => handleReasonSelection(e)}
                          className={`px-4 py-2 rounded-md mx-2 mb-2 transition-transform duration-[10000ms] hover:scale-105 focus:outline-none ${
                            reason.includes(e.replace(" ","_"))
                              ? 'bg-transparent border-2 border-red-500 text-red-500'
                              : 'bg-red-500 text-white hover:bg-red-600'
                          }`}
                        >
                          {e}
                        </button>
                      ))}
                </div>
                <button
                  onClick={() => {
                    if (reason.length > 0) {
                      knowReason ? likeQuestion() : dislikeQuestion();
                      setShowPopUp(false);
                    } else {
                      alert('Select at least one reason');
                    }
                  }}
                  className="mt-4 block w-full bg-blue-500 hover:bg-blue-600 text-blue font-semibold py-2 px-4 rounded transition-colors duration-300 focus:outline-none"
                >
                  Submit
                </button>
              </div>
                     )
                    ) 
                   :  
                  (showPopUp===2? // This is for the hint  section to be shown 
                    (
                      <div className="w-full max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-6">
                      <TabContext value={tabValue}>
                        <TabList
                          onChange={handleTabsChange}
                          className="flex justify-center mb-6 space-x-4 border-b border-gray-200"
                          aria-label="Hint tabs"
                        >
                          {curEle.hints.map((val, index) => (
                            <Tab
                              key={index}
                              label={`Hint ${index + 1}`}
                              value={index + 1}
                              className={`py-2 px-4 rounded-t-lg ${
                                tabValue === index + 1
                                  ? 'bg-blue-500 text-white'
                                  : 'bg-gray-100 text-gray-600'
                              }`}
                            />
                          ))}
                        </TabList>
                        {curEle.hints.map((val, index) => (
                          <TabPanel key={index} value={index + 1}>
                            <div className="flex justify-between items-center mb-3 -m-10" style={userData.isAdmin?{}:{display:'none'}}>
                              <span className="flex items-center space-x-2 mx-9">
                                <MdOutlineEdit
                                  className="text-gray-500 hover:text-gray-700 cursor-pointer"
                                  onClick={() => {
                                    setKnowReason(2);
                                    setHintAnswer(val);
                                  }}
                                />
                                <AiOutlineDelete
                                  className="text-gray-500 hover:text-gray-700 cursor-pointer"
                                  onClick={() => {
                                    AddHints(1, index);
                                  }}
                                />
                              </span>
                            </div>
                            <ReactQuill
                              value={knowReason === 2 ? hintAnswer : val}
                              onChange={setHintAnswer}
                             // className={`h-[150px] w-full p-3 border rounded-lg ${
                               // knowReason === 2 ? 'border-blue-500' : 'border-gray-300'
                              //} focus:outline-none focus:ring-2 focus:ring-blue-400`}
                              modules={!userData.isAdmin ?{ toolbar: false }:{toolbar:true}}// on for admin toolbar is accessible not for the users for the edit options
                              theme="snow"
                              //disabled={knowReason !== 2}
                              //readOnly={!knowReason === 2}
                              //style={knowReason===2?{}:{ cursor: "none" }}
                              className="h-[100px] w-[580px] mb-2"
                            />
                            {knowReason === 2 && (
                              <button
                                className="mt-10 w-full bg-blue text-white font-bold rounded-lg py-2 hover:bg-blue-600 transition duration-300"
                                onClick={() => {
                                  AddHints(3, index);
                                }}
                              >
                                Submit
                              </button>
                            )}
                          </TabPanel>
                        ))}
                      </TabContext>
                    </div>                    
                    )
                  :( //For the Admin or User to upload Hints
                     showPopUp===3?
                    (
                      <div className="flex flex-col justify-center items-center bg-white rounded-lg shadow-md w-100 p-4">
                      <ReactQuill
                        value={hintAnswer}
                        placeholder="Enter your Hint"
                        onChange={setHintAnswer}
                        //modules={modules}
                        //formats={formats}
                        //onChange={(e)=>setHintAnswer(e.target.value)}
                        theme="snow"
                        className="p-4 w-[800px] h-[250px]  focus:outline-none focus:border-blue resize-none "
                      />
                      <button className="mt-9 text-blue font-bold rounded px-4 py-2" onClick={()=>(AddHints(2,0))}>
                        Submit
                      </button>
                    </div>
                    )://For the video to be shown in the website 
                    (
                     <div className="flex flex-col gap-6 items-center justify-center border-4 py-[12%] md:py-[7%] md:px-[25%] lg:px-[45%] lg:py-[23%] px-[45%] rounded-xl border-blue relative ">
                    {curEle ? (
                      curEle.video ? (
                        <iframe
                          className="absolute top-0 left-0 w-[100%] h-[100%] p-5"
                          title="YouTube video player"
                          src={curEle.video}
                          allowfullscreen="allowfullscreen"
                        ></iframe>
                      ) : (
                        <div className="absolute flex flex-col">
                          <img
                            className="h-[30px] lg:h-[80px] mb-2 lg:mb-5"
                            src={playIcon}
                            alt="playIcon"
                          />
                          <p className="text-blue text-sm lg:text-xl font-semibold">
                            Video tutorial coming soon...
                          </p>
                        </div>
                      )
                    ): null}
                     </div>
                    )
                  )
                  )}

                  <div className=" flex flex- items-center justify-center gap-2 lg:gap-10 ">
                    <div
                      onClick={() => BookMarkQuestion(isBookMarked(curEle))}
                      className={
                        solvedQuestions && isBookMarked(curEle)
                          ? solvedButtonCSS.bookmarkeDiv
                          : "group cursor-pointer bg-blue2 border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[120px] lg:w-[160px] hover:bg-blue dark:bg-dark dark:hover:bg-blue  ease-in-out duration-200 "
                      }
                    >
                      <p
                        className={
                          solvedQuestions && isBookMarked(curEle)
                            ? solvedButtonCSS.text
                            : "text-blue text-sm lg:text-lg font-medium group-hover:text-white dark:group-hover:text-white"
                        }
                      >
                        {solvedQuestions && isBookMarked(curEle)
                          ? "Bookmarked"
                          : "Bookmark"}
                      </p>
                      <i
                        className={
                          solvedQuestions && isBookMarked(curEle)
                            ? solvedButtonCSS.bookmarkedIcon
                            : "fa-regular fa-bookmark text-blue group-hover:text-white dark:group-hover:text-white"
                        }
                      ></i>
                    </div>

                    <div
                      onClick={() => MarkTheQuestion(isSolved(curEle))}
                      className={
                        solvedQuestions && isSolved(curEle)
                          ? solvedButtonCSS.div
                          : "group cursor-pointer bg-blue2 border-blue border-2 rounded-md flex items-center justify-center gap-2 h-10 w-[100px] lg:w-[120px] hover:bg-blue dark:bg-dark dark:hover:bg-blue  ease-in-out duration-200 "
                      }
                    >
                      <p
                        className={
                          solvedQuestions && isSolved(curEle)
                            ? solvedButtonCSS.text
                            : "text-blue text-sm lg:text-lg font-medium group-hover:text-white dark:group-hover:text-white"
                        }
                      >
                        {solvedQuestions && isSolved(curEle)
                          ? "Solved"
                          : "Mark"}
                      </p>
                      <i
                        className={
                          solvedQuestions && isSolved(curEle)
                            ? solvedButtonCSS.icon
                            : "fa-solid fa-check text-blue group-hover:text-white dark:group-hover:text-white"
                        }
                      ></i>
                    </div>

                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={curEle && curEle.link}
                      className=" cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[100px] lg:w-[120px] dark:bg-blue hover:bg-blue7 hover:border-blue7  dark:hover:bg-blue7 dark:hover:border-blue7 hover:scale-105 ease-in-out duration-200"
                    >
                      <p className="text-white text-sm lg:text-lg font-medium">
                        Solve
                      </p>
                      <i className="fa-solid fa-link text-white "></i>
                    </a>

                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={currentConceptPlaylistLink}
                      
                      className=" cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[150px] lg:w-[150px] dark:bg-blue hover:bg-blue7 hover:border-blue7  dark:hover:bg-blue7 dark:hover:border-blue7 hover:scale-105 ease-in-out duration-200"
                    >
                      <p className="text-white text-sm lg:text-lg font-medium">
                        Topic Video
                      </p>
                      <i className="fa-solid fa-link text-white "></i>
                    </a>
{/* 
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={WHATSAPP_GROUP_LINK}
                      className=" cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[150px] lg:w-[150px] dark:bg-blue hover:bg-blue7 hover:border-blue7  dark:hover:bg-blue7 dark:hover:border-blue7 hover:scale-105 ease-in-out duration-200"
                    >
                      <p className="text-white text-sm lg:text-lg font-medium">
                        Ask query
                      </p>
                      <i className="fa-solid fa-link text-white "></i>
                    </a> */}
                  </div>
                  {userData && userData.isAdmin ? (
                    <div className="flex gap-4">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="/"
                        className=" cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[150px] lg:w-[150px] dark:bg-blue hover:bg-blue7 hover:border-blue7  dark:hover:bg-blue7 dark:hover:border-blue7 hover:scale-105 ease-in-out duration-200"
                      >
                        <button
                          onClick={f}
                          className="text-white text-sm lg:text-lg font-medium"
                        >
                          Add POTD
                        </button>
                      </a>
                      
                      <input onChange={(e)=>setDiff(e.target.value)} placeholder="desired difficulty">
                      </input>
                      <a
                        className=" cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[150px] lg:w-[150px] dark:bg-blue hover:bg-blue7 hover:border-blue7  dark:hover:bg-blue7 dark:hover:border-blue7 hover:scale-105 ease-in-out duration-200"
                      >
                        <button onClick={handleSave}
                          
                          className="text-white text-sm lg:text-lg font-medium"
                        >
                          Save to Sheet
                        </button>
                      </a>

                    
                      
                      
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* <div className="flex items-center justify-between mb-[100px]"></div> */}
            </section>
          ) : (
            <section className="bg-blue2 lg:h-screen fixed top-[60%] w-screen h-[40%] lg:top-0 lg:right-[0%] lg:w-[65%] z-5 lg:z-10 flex flex-col items-center justify-between dark:bg-dark">
              <h1 className="text-white text-center text-lg lg:text-3xl font-bold mt-[7%] lg:mt-[25%] p-10 rounded-xl bg-blue dark:bg-dark3 dark:text-blue ">
                Select a Question <br /> to continue!
              </h1>
            </section>
          )}
        </>
      ) : (
        <div className="flex items-start justify-start bg-blue2 h-screen w-screen dark:bg-dark">
          <section className="bg-blue w-screen  h-[50%] fixed lg:h-screen z-5 lg:w-[35%] p-5 dark:bg-dark2">
            <div className=" bg-blue7 p-3 rounded-lg flex flex-col items-center justify-center mt-[70px] dark:bg-dark3">
              <h1 className="text-white text-sm lg:text-2xl font-semibold mb-3">
                {currentTagName}
              </h1>
              <div className="w-[80%]">
                <ProgressBar
                  width={currentTagPercent}
                  progressBarCSS={progressBarCSS}
                />
              </div>
            </div>
            <div className="text-[1.2rem] hidden md:block font-semibold rounded-xl">
              <Select
                options={options}
                placeholder={"Select Difficulty"}
                styles={customStyle}
                onChange={(value) => setSelectedOption(value)}
                value={selectedOption}
              // maxMenuHeight={220}
              ></Select>
            </div>
            <div className="text-[1.2rem] md:hidden font-semibold rounded-xl">
              <Select
                options={options}
                placeholder={"Select Difficulty"}
                styles={customStyle}
                onChange={(value) => setSelectedOption(value)}
                value={selectedOption}
                maxMenuHeight={150}
              ></Select>
            </div>

            <div className="mt-6 ml-3 font-semibold text-white ">
              No questions of {selectedOption.label} Difficulty of{" "}
              {currentTagName} are available, kindly choose different difficulty
              options.
            </div>
          </section>

          <section className="bg-blue2 lg:h-screen fixed top-[60%] w-screen h-[10%] lg:top-0 lg:right-[0%] lg:w-[65%] z-5 lg:z-10 flex flex-col items-center justify-between dark:bg-dark">
            <div className="flex items-center justify-between -mt-20 lg:mt-[90px] w-[100%]">
              <div className="w-[100%] px-[10%] flex flex-col items-center justify-center gap-5 lg:gap-14 mt-[10px]">
                {/* ------------- like and dislike button ----------------- */}

                <div className=" flex flex- items-center w-[80%] ml-7 mt-[20px] lg:mb-[-30px]">
                  <h1 className="mx-auto text-blue text-xl lg:text-2xl font-semibold ">
                    No {selectedOption.label} Questions for {currentTagName} are
                    available.
                  </h1>
                </div>

                {/* ------------- Video Player ----------------- */}
                <div className="flex  flex-col gap-6 items-center justify-center border-[4px] md:mt-12 rounded-xl border-blue relative ">
                  <img
                    src="https://os.me/wp-content/uploads/2021/07/problem-98376_1280-450x330-center-top.jpeg.optimal.jpeg"
                    alt=""
                    className="w-[15rem] md:w-[30rem]"
                  />
                </div>
              </div>
            </div>

            {/* <div className="flex items-center justify-between mb-[100px]"></div> */}
          </section>
        </div>
      )}
    </>
  );
};

export default Solve;

