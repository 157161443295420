import React, { useEffect, useState } from "react";
import "./NavbarStyle.css";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetchProblems } from "../redux/slices/Potd";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Navbar = (props) => {
  // const [potd,setPotd]= useState({})
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  const questions = useSelector((state) => state.Potd.data);
  // console.log(questions);

  useEffect(() => {
    dispatch(fetchProblems());
    // setTodaysproblem(questions[0]);
  }, [dispatch]);

  // console.log(potd);
  function go() {
    navigate("/problemoftheday", { state: questions });
  }
  const profile = JSON.parse(window.localStorage.getItem("PROFILE_PIC"));
  const openNav = () => {
    const hamburger = document.querySelector(".hamburger");

    const links = document.querySelectorAll(".nav-links li");

    links.forEach((link) => {
      link.classList.toggle("fade");
    });

    //Hamburger Animation
  };

  const userData = useSelector((state) => state.user.user); //Fetching userData

  // <----------------- Scroll to top for Brand icon -------------------->

  function scrollUp() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  // const notify = () =>
  //   toast("Maintainance are going ON , You might face slow-loading");
  // const notify2 = () => toast("you might face some bugs ");
  

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Toggle dropdown menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // md breakpoint in Tailwind CSS
    };

    // Initial check
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [visible, setVisible] = useState(true);
  let hideTimeout;

  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;

    // Clear previous timeout to prevent immediate hiding
    clearTimeout(hideTimeout);

    // Determine if user is scrolling up or down
    if (currentScrollPosition > scrollPosition) {
      // Scrolling down, hide navbar
      setVisible(false);
    } else {
      // Scrolling up, show navbar
      setVisible(true);
    }

    // Update the scroll position state
    setScrollPosition(currentScrollPosition);

    // Set a timeout to hide the navbar after 5 seconds of inactivity
    hideTimeout = setTimeout(() => {
      setVisible(false);
    }, 5000);
  };

  useEffect(() => {
    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener and timeout on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(hideTimeout);
    };
  }, [scrollPosition]);

  return (
    <>
      <header
        className={`fixed inset-x-0 top-0 z-30 mx-auto w-full max-w-screen-md border-gray-100 bg-white/80 dark:bg-gray-800/90 py-3 shadow backdrop-blur-lg md:top-3 md:rounded-3xl lg:max-w-screen-lg transition-transform duration-300 ${
          visible ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <div className="px-4">
          {/* Hamburger menu for mobile */}
          {isMobile && (
            <div
              className=" absolute right-4 top-4 cursor-pointer"
              onClick={toggleMenu}
            >
              <div className="line1 bg-blue w-6 h-0.5 my-1"></div>
              <div className="line2 bg-blue w-6 h-0.5 my-1"></div>
              <div className="line3 bg-blue w-6 h-0.5 my-1"></div>
            </div>
          )}
          <div className="flex items-center justify-between ">
            <div className="flex shrink-0 ">
              <a aria-current="page" className="flex items-center" href="/">
                <NavLink
                  to="/"
                  onClick={scrollUp}
                  className="font-bold text-xl text-blue"
                >
                  Senior
                </NavLink>
              </a>
            </div>
            <div className="hidden md:flex md:items-center md:justify-center md:gap-5">
              <a
                aria-current="page"
                className="inline-block rounded-lg px-2 py-1 text-sm font-medium dark:text-white text-gray-900 transition-all duration-200  hover:text-gray-900"
                href="#"
              >
                {props.theme === "dark" ? (
                  <i
                    className=" fa-regular fa-sun cursor-pointer hover:text-blue ease-in-out duration-300 text-lg "
                    onClick={props.handleThemeSwitch}
                  ></i>
                ) : (
                  <i
                    className="  fa-solid fa-moon cursor-pointer hover:text-blue ease-in-out duration-300 text-lg "
                    onClick={props.handleThemeSwitch}
                  ></i>
                )}
              </a>
              <a
                aria-current="page"
                className="inline-block rounded-lg px-2 py-1 text-sm font-medium text-gray-900 dark:text-white transition-all duration-200  hover:text-gray-900"
                href="#"
              >
                <div
                  onClick={openNav}
                  className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
                >
                  <NavLink to="/learn" 
                  // onClick={notify}
                  >
                    CM Sheet
                  </NavLink>
                </div>
              </a>
              <a
                aria-current="page"
                className="inline-block dark:text-white rounded-lg px-2 py-1 text-sm font-medium text-gray-900 transition-all duration-200  hover:text-gray-900"
                href="#"
              >
                <div
                  onClick={openNav}
                  className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
                >
                  <NavLink to="/blog" 
                  // onClick={notify2}
                  >
                    Blog
                  </NavLink>
                </div>
              </a>
              <a
                aria-current="page"
                className="inline-block dark:text-white rounded-lg px-2 py-1 text-sm font-medium text-gray-900 transition-all duration-200  hover:text-gray-900"
                href="https://askcode.asksenior.in/"
              >
                AskCode
              </a>

              <a
                aria-current="page"
                className="inline-block dark:text-white rounded-lg px-2 py-1 text-sm font-medium text-gray-900 transition-all duration-200  hover:text-gray-900"
                href="#"
              >
                <div
                  onClick={openNav}
                  className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
                >
                  <NavLink to="/OA_learn">OA</NavLink>
                </div>
              </a>

              <a
                aria-current="page"
                className="inline-block dark:text-white rounded-lg px-2 py-1 text-sm font-medium  text-gray-900 transition-all duration-200  hover:text-gray-900"
                href="#"
              >
                {userData && userData.isAdmin ? (
                  <div
                    onClick={openNav}
                    className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
                  >
                    <NavLink to="/admin">Admin</NavLink>
                  </div>
                ) : (
                  ""
                )}
              </a>
            </div>
            <div className="flex items-center justify-end gap-3">
              <a className="" href="/login">
                {userData && userData.length !== 0 ? (
                  <>
                    <NavLink
                      onClick={openNav}
                      to="/profile"
                      className="flex items-center justify-center"
                    >
                      <h1 className="font-medium text-md text-blue dark:text-blue  hover:text-blue cursor-pointer ease-in-out duration-300 mr-3">
                        Hey,{" "}
                        <span className="font-medium text-md text-black dark:text-white  hover:text-blue cursor-pointer ease-in-out duration-300 ">
                          {userData.name}
                        </span>
                      </h1>
                      <img
                        className="rounded-full h-[40px]"
                        src={profile}
                        alt="profile"
                      />
                    </NavLink>
                  </>
                ) : (
                  <NavLink
                    onClick={openNav}
                    className=" py-1.5 px-7 login-button text-white bg-blue hover:scale-105 ease-in-out duration-300 font-medium rounded"
                    to="/login"
                  >
                    Login
                  </NavLink>
                )}
              </a>
            </div>
          </div>
          {isMobile && isMenuOpen && (
            <div className="absolute top-full right-0 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg py-2 z-20">
              <NavLink
                to=""
                className="block px-4 py-2 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <a
                  aria-current="page"
                  className="inline-block rounded-lg px-2 py-1 text-sm font-medium dark:text-white text-gray-900 transition-all duration-200  hover:text-gray-900"
                  href="#"
                >
                  {props.theme === "dark" ? (
                    <i
                      className=" fa-regular fa-sun cursor-pointer hover:text-blue ease-in-out duration-300 text-lg "
                      onClick={props.handleThemeSwitch}
                    ></i>
                  ) : (
                    <i
                      className="  fa-solid fa-moon cursor-pointer hover:text-blue ease-in-out duration-300 text-lg "
                      onClick={props.handleThemeSwitch}
                    ></i>
                  )}
                </a>
              </NavLink>
              <NavLink
                to="/learn"
                className="block px-4 py-2 text-gray-900 dark:text-white  dark:hover:bg-gray-700"
              >
                Learn
              </NavLink>
              <NavLink
                to="/blog"
                className="block px-4 py-2 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Blog
              </NavLink>
              <NavLink
                to="/OA_learn"
                className="block px-4 py-2 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                OA
              </NavLink>
              {userData && userData.isAdmin ? (
                <div
                  onClick={openNav}
                  className="block px-4 py-2 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 "
                >
                  <NavLink to="/admin">Admin</NavLink>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Navbar;
